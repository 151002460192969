import React from "react";
import "./Actions.css";

import { ReactComponent as EditIcon } from "common/assets/icons/edit-pencil.svg";
import { ReactComponent as DeleteIcon } from "common/assets/icons/trash.svg";
import { ReactComponent as ViewIcon } from "common/assets/icons/eye.svg";
import { ActionsProps } from "./Actions.props";
import { useNavigate } from "react-router-dom";
import { routeConstants } from "navigation";

const Actions: React.FC<ActionsProps> = ({
	isActive,
	onToggle,
	onEdit,
	onDelete,
	onClick,
	onView,
	userId,
	className,
}) => {
	const navigate = useNavigate();
	console.log("Action user", userId);
	return (
		<div className={`grid grid-flow-col gap-6 auto-cols-max ${className}`}>
			{onView && (
				<ViewIcon
					onClick={(event: any) => {
						event.stopPropagation(); // Prevent event from propagating
						if (onView) onView();
						console.log("Clicked User ID:", userId);
						navigate(`${routeConstants.userManagementDetails.route}/${userId}`);
					}}
					className="cursor-pointer stroke-passiveTextColor"
				/>
			)}
			{onToggle && (
				<label className="switch">
					<input
						type="checkbox"
						checked={isActive}
						onChange={() => onToggle && onToggle(!isActive)}
					/>
					<span className="slider round"></span>
				</label>
			)}
			{onEdit && (
				<EditIcon
					onClick={(event: any) => {
						event.stopPropagation();
						onEdit && onEdit();
					}}
					className="cursor-pointer stroke-passiveTextColor"
				/>
			)}
			{onDelete && (
				<DeleteIcon
					onClick={(event: any) => {
						event.stopPropagation();
						onDelete && onDelete();
					}}
					className="cursor-pointer stroke-passiveTextColor"
				/>
			)}
		</div>
	);
};

export default Actions;
