import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { serviceConfig } from "configs";
import OrderHistoryTable from "components/OrderHistoryTable/OrderHistoryTable";
import { useParams } from "react-router-dom";
import formatDate from "utils/formatDate";

const UserManagementDetailPage: React.FC = () => {
	const [loading, setLoading] = useState(true);
	const [userData, setUserData] = useState<any>(null);
	const { id } = useParams<{ id: string }>();

	useEffect(() => {
		const fetchUserDetails = async () => {
			try {
				const response = await fetch(`${serviceConfig.apiUrl}/users/${id}`);
				const result = await response.json();

				if (!result.data) {
					console.error("User data not found");
					setLoading(false);
					return;
				}

				setUserData(result.data);
				setLoading(false);
			} catch (error) {
				console.error("Failed to fetch user details", error);
				setLoading(false);
			}
		};

		fetchUserDetails();
	}, [id]);

	return (
		<div className="grid gap-8 p-8">
			<div className="grid gap-1">
				<Htag tag="h2" type="medium">
					User Management
				</Htag>
				<P size="p2" type="regular" className="text-passiveTextColor">
					Track, manage and forecast your customers and orders.
				</P>
			</div>
			<div className="grid gap-6">
				{loading ? (
					<p>Loading...</p>
				) : (
					<>
						<div className="border border-strokeColor">
							<Htag
								tag="h4"
								type="semibold"
								className="p-6 border-b text-passiveTextColor border-b-strokeColor"
							>
								User Info
							</Htag>
							<div className="grid grid-cols-3 gap-6 p-6">
								<div className="grid gap-2">
									<P size="p2" type="regular" className="text-passiveTextColor">
										ID
									</P>
									<P size="p1" type="medium">
										{userData?._id}
									</P>
								</div>
								<div className="grid gap-2">
									<P size="p2" type="regular" className="text-passiveTextColor">
										Name
									</P>
									<P size="p1" type="medium">
										{`${userData?.FirstName} ${userData?.LastName}`}
									</P>
								</div>
								<div className="grid gap-2">
									<P size="p2" type="regular" className="text-passiveTextColor">
										Phone Number
									</P>
									<P size="p1" type="medium">
										{userData?.MobilePhoneNumber || "No number"}
									</P>
								</div>
								<div className="grid gap-2">
									<P size="p2" type="regular" className="text-passiveTextColor">
										Email
									</P>
									<P size="p1" type="medium">
										{userData?.Email}
									</P>
								</div>
								<div className="grid gap-2">
									<P size="p2" type="regular" className="text-passiveTextColor">
										Joined Date
									</P>
									<P size="p1" type="medium">
										{formatDate(userData?.createdAt)}
									</P>
								</div>
								{userData?.Address && (
									<div className="grid gap-2">
										<P
											size="p2"
											type="regular"
											className="text-passiveTextColor"
										>
											Address
										</P>
										<P size="p1" type="medium">
											{userData?.Address}
										</P>
									</div>
								)}
							</div>
						</div>
						{/* Order history */}
						{userData?.orders?.length > 0 && (
							<div className="border border-strokeColor">
								<Htag
									tag="h4"
									type="semibold"
									className="p-6 border-b text-passiveTextColor border-b-strokeColor"
								>
									Order history
								</Htag>
								<OrderHistoryTable data={userData.orders} />
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default UserManagementDetailPage;
