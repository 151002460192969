import Actions from "common/Actions/Actions";

interface UserTableProps {
	data: any;
	onRowClick?: (order: any) => void;
	className?: string;
	onDelete?: (user: any) => void;
	onView?: (user: any) => void;
}

const UserTable: React.FC<UserTableProps> = ({
	data,
	onRowClick,
	className,
	onDelete,
	onView,
}) => {
	console.log("DATA", data);
	return (
		<table
			className={`w-full border rounded-lg border-strokeColor ${
				className && className
			}`}
		>
			<thead className="bg-white border border-strokeColor">
				<tr>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						ID
					</th>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						User
					</th>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						Phone number
					</th>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						Action
					</th>
				</tr>
			</thead>
			<tbody className="text-left">
				{data.map((item: any, idx: any) => (
					<tr
						key={idx}
						className={`${
							idx === data.length - 1
								? "border-0"
								: "border-b border-strokeColor"
						} ${idx % 2 === 0 ? "bg-white" : "bg-[#f9f9f9]"} cursor-pointer`}
						// onClick={() => onRowClick && onRowClick(item)}
					>
						<td
							className={`px-5 py-4 text-textColor font-medium max-w-[100px] ${
								idx === data.length - 1 ? "rounded-bl-lg" : ""
							}`}
						>
							{item._id}
						</td>
						<td className="px-5 py-4 font-medium text-textColor">
							{item.FirstName} {item.LastName}
						</td>
						<td className="px-5 py-4 font-medium text-textColor">
							{item.MobilePhoneNumber ? item.MobilePhoneNumber : "-"}
						</td>
						<td className="px-5 py-4 font-medium text-textColor">
							<Actions
								userId={item._id}
								onView={() => onView && onView(item)}
								onDelete={() => onDelete && onDelete(item)}
							/>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default UserTable;
