import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import Pagination from "components/Pagination/Pagination";
import UserTable from "components/UserTable/UserTable";
import { serviceConfig } from "configs";
import Modal from "common/Modal/Modal";
import { SearchInput } from "common/Search/Search";
import { useNavigate } from "react-router-dom";
import { routeConstants } from "navigation";

const UserManagementPage: React.FC = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [deletingUser, setDeletingUser] = useState<any>(null);
	const [loading, setLoading] = useState(true);
	const [totalPages, setTotalPages] = useState(0);
	const [usersData, setUsersData] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const navigate = useNavigate();

	const fetchUsers = async (page = 1, search = "") => {
		try {
			setLoading(true);

			const url = `${
				serviceConfig.apiUrl
			}/users?page=${page}&search=${encodeURIComponent(search)}`;

			const response = await fetch(url);
			const result = await response.json();

			if (result.success) {
				setUsersData(result.data);
				setTotalPages(result.totalPages);
			} else {
				console.error("Failed to fetch users:", result.message);
			}

			setLoading(false);
		} catch (error) {
			console.error("Error fetching users:", error);
			setLoading(false);
		}
	};

	const handleSearch = () => {
		setCurrentPage(1);
		fetchUsers(1, searchQuery);
	};

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
		fetchUsers(page, searchQuery);
	};

	const deleteUser = async () => {
		if (!deletingUser) return;
		try {
			const response = await fetch(
				`${serviceConfig.apiUrl}/users/${deletingUser._id}`,
				{
					method: "DELETE",
				}
			);

			if (response.ok) {
				alert("User deleted successfully!");
				setDeletingUser(null);

				fetchUsers(currentPage, searchQuery);
			} else {
				alert("Failed to delete user.");
			}
		} catch (error) {
			console.error("Failed to delete user", error);
		}
	};

	const handleViewUser = (user: any) => {
		navigate(`${routeConstants.userManagementDetails.route}/${user._id}`);
	};

	useEffect(() => {
		fetchUsers(currentPage, searchQuery);
	}, [currentPage]);

	return (
		<div className="p-8">
			<div className="grid gap-1">
				<Htag tag="h2" type="medium">
					User Management
				</Htag>
				<P size="p2" type="regular" className="text-passiveTextColor">
					Track, manage and forecast your customers and orders.
				</P>
			</div>
			<div className="grid gap-6">
				{loading ? (
					<p>Loading...</p>
				) : (
					<>
						<div className="grid gap-4">
							<div className="grid items-center grid-cols-2 px-5 py-3 mt-6 border rounded-md border-strokeColor">
								<Htag tag="h4" type="semibold">
									Users
								</Htag>
								<SearchInput
									placeholder="Search"
									value={searchQuery}
									onChange={(e) => setSearchQuery(e.target.value)}
									handleSearch={handleSearch}
								/>
							</div>
							<UserTable
								data={usersData}
								onDelete={(item) => setDeletingUser(item)}
								onView={handleViewUser}
							/>
						</div>
						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={handlePageChange}
						/>
					</>
				)}
			</div>

			{deletingUser && (
				<Modal
					isOpen={!!deletingUser}
					onClose={() => setDeletingUser(null)}
					title="Delete User"
					desc={`Are you sure you want to delete this user? This action cannot be undone.`}
					onConfirm={deleteUser}
					onCancel={() => setDeletingUser(null)}
				/>
			)}
		</div>
	);
};

export default UserManagementPage;
